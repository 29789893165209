import styled from "styled-components"
import logo from "../images/one_color_logo.png"

const HeaderStyles = styled.div`
  top: 0;
  z-index: 2000;
  box-sizing: border-box;
  max-width: 1200px;
  width: 100%;
  height: 9rem;
  padding: 1rem;
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-content: space-between;

  @media only screen and (max-width: ${props => props.breakpoints.medium}) {
    grid-template-columns: 1fr;
    justify-content: end;
    position: fixed;
  }

  .brand {
    display: flex;

    @media only screen and (max-width: ${props => props.breakpoints.medium}) {
      display: none;
    }

    align-items: center;
    .brand__logo {
      height: 7rem;
      width: 7rem;
      background-image: url(${logo});
      background-size: cover;
    }

    .brand__text {
      margin-left: 1rem;
      text-transform: uppercase;
      font-size: 2rem;
      color: var(--color-grey-light-1);
    }
  }

  .link {
    display: flex;
    align-items: center;
    height: 100%;
    justify-content: flex-end;
    margin-right: 1rem;
    .link__button {
      align-self: center;
      text-decoration: none;
      font-size: 2rem;
      color: var(--color-grey-light-1);
      text-transform: uppercase;
      margin-left: 1rem;
      border-left: 2px solid var(--color-grey-light-1);
      padding-left: 1rem;

      transition: all 0.5s;

      &:hover {
        transform: scale(1.05);
      }
    }
  }
`
export default HeaderStyles
