import styled from "styled-components"

const MenuCategoryItemStyles = styled.div`
  .menu__item--wrapper {
    position: relative;
    .menu__item {
      box-sizing: inherit;
      max-height: 600px;
      padding: 2rem;
      margin-top: 3rem;
      min-height: 0;

      display: grid;
      background-color: rgba(255, 255, 255, 0.2);

      width: 98%;
      grid-template-columns: 0.8fr 1.2fr;

      @media only screen and (max-width: ${props => props.breakpoints.medium}) {
        grid-template-columns: 1fr;
      }

      @media only screen and (min-width: ${props => props.breakpoints.medium}) {
        transform: skewX(-12deg);
      }

      .menu__item-image {
        width: 100%;
        height: inherit;
        display: flex;
        justify-content: center;

        @media only screen and (min-width: ${props =>
            props.breakpoints.medium}) {
          transform: skewX(12deg);
          border-radius: 50% 50%;
        }

        .gatsby-image-wrapper {
          width: 40rem;
          height: 40rem;

          @media only screen and (min-width: ${props =>
              props.breakpoints.medium}) {
            border-radius: 50% 50%;
          }

          @media only screen and (max-width: ${props =>
              props.breakpoints.medium}) {
            border-radius: none;
            width: 33rem;
            height: 33rem;
          }

          object-fit: cover;
        }
      }

      .menu__item-name {
        text-transform: uppercase;
        font-size: 3rem;
        margin-bottom: 1rem;
        border-bottom: 2px solid var(--color-grey-light-1);
        @media only screen and (max-width: ${props =>
            props.breakpoints.small}) {
          font-size: 2rem;
        }

        span {
          color: var(--color-primary);
          margin-left: 1rem;
        }
      }

      .menu__item-price {
        text-transform: uppercase;
        font-size: 3rem;
        margin-bottom: 1rem;

        @media only screen and (max-width: ${props =>
            props.breakpoints.small}) {
          font-size: 2rem;
        }
      }

      .menu__item-multi-price {
        text-transform: uppercase;
        font-size: 1.7rem;
        margin-bottom: 2rem;
        font-weight: 500;
      }

      .menu__item-description {
        margin-left: 2rem;
        @media only screen and (max-width: ${props =>
            props.breakpoints.small}) {
          margin-top: 1rem;
        }

        @media only screen and (min-width: ${props =>
            props.breakpoints.medium}) {
          transform: skewX(12deg);
        }
        color: var(--color-grey-light-2);

        display: flex;
        flex-direction: column;
        justify-content: start;
        align-items: start;

        .menu__item-description-header {
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 100%;
          font-family: var(--font-primary);
          font-weight: 100;
          color: var(--color-grey-light-1);
        }

        .menu__item-description-text {
          font-size: 2.5rem;
          margin-bottom: 4rem;
          font-weight: 300;
          font-family: $font-display;

          @media only screen and (max-width: ${props =>
              props.breakpoints.small}) {
            margin-bottom: 2rem;
          }
        }

        .menu__item-description-ingredient {
          font-size: 2rem;
        }

        ul {
          list-style: circle;
          padding-left: 2rem;
          font-family: var(--font-display);
        }
      }
    }

    .close-menu-category-button {
      position: absolute;
      bottom: 0;
      right: 0;
      font-size: 5rem;
      color: $color-grey-dark-1;
      transition: all 0.2s ease-in-out;
      background-color: var(--color-grey-light-1);
      border-radius: 50% 50%;
      width: 7rem;
      height: 7rem;
      text-align: center;
      right: -1rem;
      @media only screen and (max-width: ${props => props.breakpoints.medium}) {
        right: 2rem;
        bottom: -3rem;
      }

      &:hover {
        transform: scale(1.2);
        cursor: pointer;
      }
    }
  }
`
export default MenuCategoryItemStyles
