import React from "react"
import Navigation from "./Navigation"
import HeaderStyles from "../styles/HeaderStyles"
import { useContext } from "react"
import ThemeContext from "./ThemeContext"

export default function Header() {
  const [theme] = useContext(ThemeContext)
  return (
    <HeaderStyles breakpoints={theme.breakpoints}>
      <div className="brand">
        <div className="brand__logo"></div>
        <div className="brand__text">KEI ASIAN KITCHEN</div>
      </div>
      <Navigation />
    </HeaderStyles>
  )
}
