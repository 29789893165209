import NavigationStyles from "../styles/NavigationStyles"
import React, { useContext, useRef, useState } from "react"
import ThemeContext from "./ThemeContext"

export default function Navigation() {
  const checkNav = useRef(null)
  const [checked, setChecked] = useState(false)
  const [theme] = useContext(ThemeContext)

  const checkboxChange = event => {
    setChecked(event.currentTarget.checked)
  }

  return (
    <NavigationStyles breakpoints={theme.breakpoints}>
      <nav className="desktop-nav">
        <div className="link">
          <a href="#menu" className="link__button">
            menu
          </a>
          <a href="#hours" className="link__button">
            hours
          </a>
          <a href="#location" className="link__button">
            location
          </a>
        </div>
      </nav>
      <div className="navigation mobile-nav">
        <input
          checked={checked}
          type="checkbox"
          className="navigation__checkbox"
          id="navi-toggle"
          ref={checkNav}
          onChange={checkboxChange}
        ></input>
        <label htmlFor="navi-toggle" className="navigation__button">
          <span className="navigation__icon"></span>
        </label>
        <div className="navigation__background">&nbsp;</div>
        <nav className="navigation__nav">
          <ul className="navigation__list">
            <li className="naviation__item">
              <a
                onClick={() => setChecked(false)}
                href="#home"
                className="navigation__link"
              >
                <span className="navigation__link--text">Home</span>
              </a>
            </li>
            <li className="naviation__item">
              <a
                onClick={() => setChecked(false)}
                href="#menu"
                className="navigation__link"
              >
                <span className="navigation__link--text">Menu</span>
              </a>
            </li>
            <li className="naviation__item">
              <a
                onClick={() => setChecked(false)}
                href="#hours"
                className="navigation__link"
              >
                <span className="navigation__link--text">Hours</span>
              </a>
            </li>
            <li className="naviation__item">
              <a
                onClick={() => setChecked(false)}
                href="#location"
                className="navigation__link"
              >
                <span className="navigation__link--text">Location</span>
              </a>
            </li>
          </ul>
        </nav>
      </div>
    </NavigationStyles>
  )
}
