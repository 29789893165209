import React, { useContext } from "react"
import FullScreenHeroStyles from "../styles/FullScreenHeroStyles"
import ThemeContext from "./ThemeContext"

export default function FullScreenHero() {
  const [theme] = useContext(ThemeContext)
  return (
    <FullScreenHeroStyles id="home" breakpoints={theme.breakpoints}>
      <article id="home">
        <div className="hero-logo"></div>
        <div className="hero-tag-line">kei asian kitchen</div>
        <button className="hero-button">
          <a href="#menu" className="hero-button--text">
            see our menu
          </a>
        </button>
      </article>
    </FullScreenHeroStyles>
  )
}
