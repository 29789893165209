import React, { useContext } from "react"
import LocationStyles from "../styles/LocationStyles"
import ThemeContext from "./ThemeContext"

const Location = () => {
  const [theme] = useContext(ThemeContext)
  const iframe = () => {
    return {
      __html:
        '<iframe width="100%" height="100%" frameborder="0" style="border:0" src="https://www.google.com/maps/embed/v1/place?key=AIzaSyBHuHXsD_30cth3jSNMeiFqmFuukUp6ouQ&q=kei+asian+kitchen+athens+tx" allowfullscreen> </iframe>',
    }
  }
  return (
    <LocationStyles breakpoints={theme.breakpoints}>
      <div className="location-header" id="location">
        Location
      </div>
      <a
        href="https://maps.google.com/?q=kei+asian+kitchen+athens+tx"
        className="location-address"
        target="blank"
      >
        220 Crestway St, Ste 108, Athens TX 75751
      </a>
      <a className="location-phone" href="tel:903-292-5125">
        903-292-5125
      </a>
      <div className="map" dangerouslySetInnerHTML={iframe()} />
    </LocationStyles>
  )
}

export default Location
