import React from "react"
import formatMoney from "../utils/formatMoney"
import { FaPepperHot } from "react-icons/fa"
import Img from "gatsby-image"

export default function MenuItem({
  name,
  ingredient,
  imageSrc,
  description,
  price,
  multiPrice,
  spicy,
}) {
  const generateIngredientList = () => {
    return ingredient.map(ingre => {
      return <li key={ingre.id}>{ingre.name}</li>
    })
  }
  const generatePrices = () => {
    return Object.keys(price)
      .map(key => {
        if (price[key] !== null && !/[a-zA-Z]/.test(price[key])) {
          return `${key.replace("_", " ")} ${formatMoney(price[key])}`
        } else {
          return null
        }
      })
      .filter(v => v)
      .sort((a, b) => {
        return parseFloat(a.split("$")[1]) - parseFloat(b.split("$")[1])
      })
      .join(", ")
  }

  return (
    <div className="menu__item">
      <div className="menu__item-image">
        <Img fluid={imageSrc} alt={name} />
      </div>
      <div className="menu__item-description">
        <div className="menu__item-description-header">
          <div className="menu__item-name">
            {name}
            <span>{spicy && <FaPepperHot />}</span>
          </div>
          {price?.one_price && (
            <div className="menu__item-price">
              {formatMoney(price.one_price)}
            </div>
          )}
        </div>
        {multiPrice && (
          <div className="menu__item-description-header">
            <div className="menu__item-multi-price">{generatePrices()}</div>
          </div>
        )}
        <div className="menu__item-description-text">{description}</div>
        <div className="menu__item-description-ingredient">
          <ul>{generateIngredientList()}</ul>
        </div>
      </div>
    </div>
  )
}
