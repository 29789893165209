import styled from "styled-components"

const BusinessHourStyles = styled.div`
  grid-column: content-start / content-end;
  width: 100%;
  padding: 1rem;
  display: grid;
  justify-content: center;

  .time__header {
    margin-bottom: 2rem;
    color: var(--color-grey-light-1);
    font-size: 4rem;
    font-weight: 300;
    text-transform: uppercase;
    width: 100%;
    display: flex;
    justify-content: center;
    font-family: var(--font-primary);
  }

  .time__schedule {
    font-size: 2rem;
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 2rem;
    font-weight: 600;
    color: var(--color-grey-light-2);

    .time__schedule--wrapper {
      @media only screen and (min-width: ${props => props.breakpoints.medium}) {
        display: flex;
        flex-direction: column;
        align-items: center;
      }
    }

    .time__schedule-day {
      justify-self: flex-start;
    }

    .time__schedule-time {
      justify-self: flex-end;
      @media only screen and (max-width: ${props => props.breakpoints.medium}) {
        justify-self: flex-start;
      }
    }

    .time__schedule--note {
      padding: 1rem 0;
      grid-column: 1 / -1;
      font-size: 2rem;
      color: var(--color-grey-light-2);
    }
  }

  .time__note {
    margin-top: 1rem;
    font-size: 2rem;
    font-weight: 500;
    color: var(--color-grey-light-2);
  }
`
export default BusinessHourStyles
