import styled from "styled-components"

const MenuCategoryStyles = styled.section`
  grid-column: content-start / content-end;
  max-width: 100%;
  display: grid;
  .menu-category-header {
    text-transform: uppercase;
    font-size: 4rem;
    display: flex;
    justify-content: center;
    padding: 1rem 0;
    color: var(--color-grey-light-1);
  }

  .menu-category {
    display: flex;
    flex-direction: column;
    box-sizing: border-box;

    .category-name {
      box-sizing: inherit;
      min-height: 10rem;
      background-color: var(--color-grey-dark-1);
      border: 1rem rgba(0, 0, 0, 0);

      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 2rem;
      color: var(--color-grey-light-1);
      text-transform: uppercase;
      font-weight: 400;
      transition: all 0.5s;

      max-width: 98%;

      @media only screen and (max-width: ${props => props.breakpoints.medium}) {
        max-width: 100%;
      }

      @media only screen and (min-width: ${props => props.breakpoints.medium}) {
        transform: skewX(-12deg);
      }

      &:hover {
        @media only screen and (min-width: ${props =>
            props.breakpoints.medium}) {
          transform: scale(1.05) skewX(-12deg);
          cursor: pointer;
        }

        .category-name--wrapper {
          @media only screen and (min-width: ${props =>
              props.breakpoints.medium}) {
            transform: skewX(12deg);
          }
        }
      }
      .category-name--wrapper {
        @media only screen and (min-width: ${props =>
            props.breakpoints.medium}) {
          transform: skewX(12deg);
        }
      }
    }

    .category-item--wrapper {
      box-sizing: border-box;
      transition: max-height 0.5s ease-in-out, transform 1s;
      max-height: 0;

      transform: translateX(-100vw);
      @media only screen and (max-width: ${props => props.breakpoints.medium}) {
        transform: translateX(-200vw);
      }

      .menu__item {
        transition: max-height 0.5s;
        max-height: 0;
      }

      &.animated-menu-item {
        transition: all 1s ease;
        max-height: 100%;
        margin-bottom: 3rem;
        .menu__item {
          transition: max-height 0.5s;
          height: auto;
          max-height: 100%;
        }

        transform: translateX(0);
        @media only screen and (max-width: ${props =>
            props.breakpoints.medium}) {
          transform: translateX(0);
        }
      }
    }
  }
`
export default MenuCategoryStyles
