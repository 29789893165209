import React, { useContext } from "react"
import MenuCategoryItemStyles from "../styles/MenuCategoryItemStyles"
import MenuItem from "./MenuItem"
import ThemeContext from "./ThemeContext"
import { FaAngleUp } from "react-icons/fa"

export default function MenuCategoryItem({
  items,
  categories,
  categoryName,
  handleClose,
}) {
  const [theme] = useContext(ThemeContext)
  const generateMenuItems = () => {
    return items.map(item => {
      let found = item.category.find(cate => cate.Name === categoryName)
      if (found !== undefined) {
        return (
          <MenuItem
            key={item.id}
            name={item.name}
            imageSrc={item.image !== null ? item.image.asset.fluid : ""}
            ingredient={item.ingredient}
            description={item.description}
            price={item.item_price}
            multiPrice={item.has_protein_options}
            spicy={item.spicy}
          />
        )
      } else {
        return null
      }
    })
  }

  return (
    <MenuCategoryItemStyles breakpoints={theme.breakpoints}>
      <div className="menu__item--wrapper">
        {generateMenuItems()}
        <div className="close-menu-category-button">
          <FaAngleUp
            className="fas fa-angle-up"
            onClick={handleClose}
          ></FaAngleUp>
        </div>
      </div>
    </MenuCategoryItemStyles>
  )
}
