import styled from "styled-components"

const FooterStyles = styled.footer`
  padding: 4rem;
  grid-column: content-start / content-end;
  justify-self: center;

  .copyright {
    font-family: var(--font-primary);
    font-size: 1.8rem;
    color: var(--color-grey-light-1);
    font-weight: 600;
    @media only screen and (max-width: ${props => props.breakpoints.medium}) {
      font-size: 1.5rem;
    }
  }
`
export default FooterStyles
