import React, { useContext } from "react"
import BusinessHourStyles from "../styles/BusinessHourStyles"
import ThemeContext from "./ThemeContext"

export default function BusinessHour({ business_hour }) {
  const [theme] = useContext(ThemeContext)
  const generateBusinessHours = () => {
    return business_hour.map((d, index) => {
      if (!d.status) {
        return (
          <div className="time__schedule" key={index}>
            <div className="time__schedule-day">{d.name}</div>
            <div className="time__schedule-time">Close</div>
          </div>
        )
      } else {
        return (
          <div className="time__schedule" key={index}>
            <div className="time__schedule-day">{d.name}</div>
            <div className="time__schedule-time">
              {`${d.open_hour}AM - ${d.closing_hour}PM`}
              <sup>*</sup>
            </div>
          </div>
        )
      }
    })
  }
  return (
    <BusinessHourStyles breakpoints={theme.breakpoints}>
      <div className="time__header" id="hours">
        business hours
      </div>
      <div className="time__schedule--wrapper">{generateBusinessHours()}</div>
      <div className="time__note">
        * Last order is 15 minutes before closing and for take out and to go
        only
      </div>
    </BusinessHourStyles>
  )
}
