import styled from "styled-components"
import hero_2 from "../images/hero_2.jpg"
import color_logo from "../images/color_logo.png"

const FullScreenHeroStyles = styled.section`
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 10;
  background-image: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)),
    url(${hero_2});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: bottom;
  overflow: hidden;

  display: flex;
  justify-content: center;
  align-items: center;

  article {
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 100%;
  }

  .hero-logo {
    justify-self: center;
    background-image: url(${color_logo});
    background-size: cover;
    width: 40rem;
    height: 40rem;

    @media only screen and (max-width: ${props => props.breakpoints.small}) {
      width: 32rem;
      height: 32rem;
    }
  }

  .hero-tag-line {
    font-size: 6rem;
    font-weight: 600;
    text-transform: uppercase;
    color: var(--color-grey-light-1);
    @media only screen and (max-width: ${props => props.breakpoints.medium}) {
      margin-top: 2rem;
      font-size: 4rem;
    }

    @media only screen and (max-width: ${props => props.breakpoints.small}) {
      margin-top: 2rem;
      font-size: 3.5rem;
    }
  }

  .hero-button {
    box-sizing: border-box;
    height: 6rem;
    font-size: 3rem;
    text-transform: uppercase;
    transform: skewX(-40deg) translateX(-24px);
    transform-origin: bottom left;
    padding: 1rem 2rem;
    background-color: var(--color-primary);
    border: none;

    transition: all 1s;

    @media only screen and (max-width: ${props => props.breakpoints.small}) {
      margin-top: 1rem;
      font-size: 3.5rem;
    }

    &:hover {
      transform: skewX(-40deg) translateX(-20px) scale(1.1);
      cursor: pointer;
    }

    a {
      display: inline-block;
      transform: skewX(40deg);
    }

    &--text {
      font-weight: 500;
      color: var(--color-grey-light-1);
      text-decoration: none;
    }
  }
`

export default FullScreenHeroStyles
