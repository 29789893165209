import { graphql } from "gatsby"
import React from "react"
import FullScreenHero from "../components/FullScreenHero"
import Header from "../components/Header"
import MenuCategory from "../components/MenuCategory"
import Location from "../components/Location"
import Footer from "../components/Footer"
import BusinessHour from "../components/BusinessHour"
import SEO from "../components/seo"

const IndexPage = ({ data }) => {
  return (
    <div id="root">
      <SEO title="Home" />
      <Header />
      <FullScreenHero />
      <div className="container">
        <MenuCategory
          items={data.items.nodes}
          categories={data.categories.nodes}
        />
        <BusinessHour business_hour={data.business_hour.nodes} />
        <Location />
        <Footer />
      </div>
    </div>
  )
}

export default IndexPage

export const query = graphql`
  query itemQuery {
    business_hour: allSanityBusinessHour(sort: { fields: order }) {
      nodes {
        name
        open_hour
        closing_hour
        is_holiday
        order
        status
      }
    }
    categories: allSanityMenuCategory(sort: { fields: order }) {
      nodes {
        Name
        id
        order
      }
    }
    items: allSanityItem(sort: { fields: name }) {
      nodes {
        name
        no_meat
        has_protein_options
        item_price {
          beef
          chicken
          combination
          id
          no_meat
          one_price
          shrimp
        }
        optional_ingredient {
          name
        }
        image {
          asset {
            fluid(maxWidth: 500) {
              ...GatsbySanityImageFluid
            }
          }
        }
        description
        id
        ingredient {
          id
          name
          vegetarian
        }
        spicy
        category {
          id
          Name
        }
      }
    }
  }
`
