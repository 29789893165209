import styled from "styled-components"

const NavigationStyles = styled.div`
  display: flex;
  justify-content: flex-end;

  .desktop-nav {
    @media only screen and (max-width: ${props => props.breakpoints.medium}) {
      display: none;
    }
  }

  .navigation {
    position: relative;

    @media (min-width: ${props => props.breakpoints.medium}) {
      display: none;
    }

    .navigation__checkbox {
      display: none;
    }

    .navigation__button {
      background-color: var(--color-white);
      height: 7rem;
      width: 7rem;
      border-radius: 50%;
      z-index: 2000;
      box-shadow: 0 1rem 3rem rgba(var(--color-black), 0.1);
      text-align: center;
      cursor: pointer;
      display: block;
      position: absolute;
      right: 1rem;
      top: 1rem;

      @media only screen and (max-width: ${props => props.breakpoints.medium}) {
        right: 1rem;
        top: 1rem;
      }
    }

    .navigation__background {
      height: 6rem;
      width: 6rem;
      border-radius: 50%;
      top: 1rem;
      right: 1.5rem;
      position: absolute;
      background-image: radial-gradient(
        var(--color-primary-light),
        var(--color-primary-dark)
      );
      z-index: 1000;
      transition: transform 0.8s cubic-bezier(0.86, 0, 0.07, 1);
      display: inline-block;
    }

    .navigation__nav {
      height: 100vh;
      position: fixed;
      top: 0;
      left: 0;
      z-index: 1500;

      opacity: 0;
      left: -100%;
      width: 100%;
      transition: all 0.8s cubic-bezier(0.68, -0.55, 0.265, 1.55);
    }

    .navigation__list {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      list-style: none;
      text-align: center;
      width: 100%;
    }

    .navigation__item {
      margin: 1rem;
    }

    .navigation__link {
      &:link,
      &:visited {
        display: inline-block;
        font-size: 3rem;
        font-weight: 600;
        padding: 1rem 2rem;
        color: var(--color-white);
        text-decoration: none;
        text-transform: uppercase;
        background-image: linear-gradient(
          120deg,
          rgba(0, 0, 0, 0) 0%,
          rgba(0, 0, 0, 0) 50%,
          var(--color-white) 50%
        );
        background-size: 250%;
        transition: all 0.4s;
        transform: skewX(-40deg);

        span {
          transform: skewX(40deg);
          margin-right: 1.5rem;
          display: inline-block;
        }
      }

      &:hover,
      &:active {
        background-position: 100%;
        color: var(--color-primary);
        transform: translateX(1rem) skewX(-40deg);
      }
    }

    .navigation__checkbox:checked ~ .navigation__background {
      transform: scale(80);
    }

    .navigation__checkbox:checked ~ .navigation__nav {
      left: 0;
      width: 100%;
      opacity: 1;
    }

    //ICON
    .navigation__icon {
      position: relative;
      margin-top: 3.5rem;

      &,
      &::before,
      &::after {
        width: 3rem;
        height: 2px;
        background-color: var(--color-grey-dark-1);
        display: inline-block;
      }
      &::before,
      &::after {
        content: "";
        position: absolute;
        left: 0rem;
        transition: all 0.2s;
      }

      &::before {
        top: -0.8rem;
      }

      &::after {
        top: 0.8rem;
      }
    }

    .navigation__button:hover .navigation__icon::before {
      top: -1rem;
    }

    .navigation__button:hover .navigation__icon::after {
      top: 1rem;
    }

    .navigation__checkbox:checked + .navigation__button .navigation__icon {
      background-color: rgba(0, 0, 0, 0);
    }

    .navigation__checkbox:checked
      + .navigation__button
      .navigation__icon::before {
      top: 0;
      transform: rotate(137deg);
    }

    .navigation__checkbox:checked
      + .navigation__button
      .navigation__icon::after {
      top: 0;
      transform: rotate(-135deg);
    }
  }
`
export default NavigationStyles
