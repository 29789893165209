import React, { useContext } from "react"
import FooterStyles from "../styles/FooterStyles"
import ThemeContext from "../components/ThemeContext"

export default function Footer() {
  const [theme] = useContext(ThemeContext)
  return (
    <FooterStyles breakpoints={theme.breakpoints}>
      <p className="copyright">
        {`\u00A9 Copyright ${new Date().getFullYear()} by KEI ASIAN KITCHEN.`}
      </p>
    </FooterStyles>
  )
}
