import React, { useContext } from "react"
import ThemeContext from "./ThemeContext"
import MenuCategoryStyles from "../styles/MenuCategoryStyles"
import MenuCategoryItem from "./MenuCategoryItem"

export default function MenuCategory({ items, categories }) {
  const [theme] = useContext(ThemeContext)
  const refs = []
  const clickExpanse = (id, index) => {
    document.getElementById(id).classList.toggle("animated-menu-item")
    window.scrollTo(0, refs[index].offsetTop - 50)
  }

  const generateMenu = () => {
    return categories.map((categ, index) => {
      return (
        <div key={categ.id}>
          <a
            href={`#${categ.id}-name`}
            className="category-name"
            ref={input => refs.push(input)}
            onClick={() => clickExpanse(categ.id, index)}
          >
            <div className="category-name--wrapper">{categ.Name}</div>
          </a>
          <div id={`${categ.id}`} className={`category-item--wrapper`}>
            <MenuCategoryItem
              categoryName={categ.Name}
              categories={categories}
              items={items}
              handleClose={() => clickExpanse(categ.id, index)}
            />
          </div>
        </div>
      )
    })
  }
  return (
    <MenuCategoryStyles
      breakpoints={theme.breakpoints}
      className="menu-section-category"
    >
      <div className="menu-category-header" id="menu">
        menu
      </div>
      <div className="menu-category">{generateMenu()}</div>
    </MenuCategoryStyles>
  )
}
