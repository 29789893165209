import styled from "styled-components"

const LocationStyles = styled.div`
  grid-column: content-start / content-end;
  justify-content: center;
  margin-top: 2rem;
  .location-header {
    margin-bottom: 2rem;
    color: var(--color-grey-light-1);
    font-size: 4rem;
    font-weight: 300;
    text-transform: uppercase;
    width: 100%;
    display: flex;
    justify-content: center;
    font-family: var(--font-primary);
  }

  .location-address {
    display: flex;
    font-size: 2rem;
    color: var(--color-grey-light-2);
    font-family: var(--font-primary);
    font-weight: 600;
    justify-content: center;
    text-align: center;
    /* @media only screen and (max-width: ${props =>
      props.breakpoints.medium}) {
      text-align: center;
    } */
  }

  .location-phone {
    display: flex;
    justify-content: flex-start;
    font-size: 2rem;
    color: var(--color-grey-light-2);
    font-family: $font-primary;
    font-weight: 600;
    justify-content: center;
  }

  .map {
    width: 100%;
    height: 600px;
    margin-top: 1rem;

    @media only screen and (max-width: ${props => props.breakpoints.medium}) {
      width: 100vw;
      height: 100vh;
    }
  }
`
export default LocationStyles
